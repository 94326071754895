import * as React from "react"
import { Seo } from "../../components/seo"
import Layout from '../../components/layout'
import Sidebar from '../../components/sidebar'
import { StaticImage } from "gatsby-plugin-image"

const RepairsPage = () => {
  return (
	<Layout>
		<section id="hero">
			<StaticImage src="../images/hero-images/hero-image-1.jpg" alt="Hero Background" class="heroImage" />
			<div className="heroOverlay"></div>
			<div className="container">
				<h1>Repairs Program</h1>
			</div>
		</section>
		<section id="pageContent">
			<div className="container withSidebar">
				<main>
                    <section>
                        <h2>A Brush With Kindness (ABWK)</h2>
                        <p>A Brush With Kindness (ABWK) is a home repair program offered through Habitat for Humanity. It provides basic interior and exterior home repair services for low to moderate-income homeowners who reside in Oneida and Vilas County, Wisconsin. ABWK assists homeowners who are unable to complete repairs on their own due to age, finances, or physical ability.</p>
                    </section>

                    <section>
                        <div className="d-flex align-middle mb">
                            <StaticImage src="../../images/brush.png" alt="Brush" />
                            <h2 className="mb-0">Home Preservation</h2>
                        </div>
                        <p>Exterior services that help preserve the home that are eligible through this program include:</p>
                        <ul>
                            <li>Power wash exterior siding of home</li>
                            <li>Minor repairs to siding, fascia, and soffit</li>
                            <li>Scrape and paint exterior of home</li>
                            <li>Gutter cleaning</li>
                            <li>Landscaping</li>
                        </ul>
                    </section>

                    <section>
                        <div className="d-flex align-middle mb">
                            <StaticImage src="../../images/house.png" alt="Brush" />
                            <h2 className="mb-0">Aging in Place</h2>
                        </div>
                        <p>Services to modify homes for people 60+ to improve activities of daily living and live safely in their home as they age. Modifications can be for current or anticipated needs, including:</p>
                        <ul>
                            <li>Wheelchair Ramps</li>
                            <li>Step Repair</li>
                            <li>Handrails, Railings, and Grab bars</li>
                            <li>Tub Cut Outs</li>
                            <li>Handheld Shower Head Extension</li>
                            <li>ADA Compliant Toilets</li>
                            <li>Changing turn-style door and faucet handles to lever-style handles</li>
                        </ul>
                    </section>

                    <section>
                        <div className="d-flex align-middle mb">
                            <StaticImage src="../../images/saw.png" alt="Brush" />
                            <h2 className="mb-0">Critical Home Repair</h2>
                        </div>
                        <p>Interior or exterior work performed to address health and safety issues to allow safe and healthy living and continued homeownership. Eligible projects must demonstrate one of these basic health or safety needs:</p>
                        <ul>
                            <li>Fire Safety</li>
                            <li>Smoke alarms and carbon monoxide detectors</li>
                            <li>Home Safety</li>
                            <li>Repair/replacement of non-functional:
                                <ul>
                                    <li>Doors and locks</li>
                                    <li>Windows</li>
                                    <li>Security doors</li>
                                </ul>
                            </li>
                            <li>Improved exterior lighting</li>
                            <li>Breaks that prevent egress in event of emergency, including:
                                <ul>
                                    <li>Gates</li>
                                    <li>Porches, steps, and walkways</li>
                                </ul>
                            </li>
                            <li>Minor electrical work (light fixtures, outlets)</li>
                        </ul>
                    </section>

                    <section>
                        <h2>Ineligible Home Repairs</h2>
                        <p>Ineligible activities include but are not limited to:</p>
                        <ul>
                            <li>Cosmetic or luxury improvements</li>
                            <li>Repairs to outbuildings or garages unless connected to the home and a source of code or safety concern</li>
                            <li>Improvements or installation of porches/decks that do not affect safe egress</li>
                            <li>Ongoing landscaping work</li>
                            <li>Tree removal</li>
                            <li>Additions</li>
                            <li>Fireplaces</li>
                            <li>Pools</li>
                            <li>Hot tubs</li>
                            <li>Kitchen/bath cabinetry</li>
                            <li>Driveway or sidewalk repair (except in cases where it is deemed necessary for Aging in Place or safety)</li>
                        </ul>
                    </section>

                    <section>
                        <h2>Interested in a Home Repair?</h2>
                        <p>Complete this form: <a href="https://forms.gle/d3Ju82Tfy2TfZj147" target="_blank">Home Repair Application</a> and we will contact you.</p>
                    </section>
				</main>
				<Sidebar />
			</div>
		</section>
	</Layout>
  )
}
export default RepairsPage

export const Head = () => (
	<Seo
        title="Repairs Program | Habitat For Humanity Northwoods Wisconsin"
    />
)
